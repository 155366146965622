<script setup lang="ts">
import SaveButton from '@app/components/ui/button/SaveButton.vue'
import Card from '@app/components/ui/card/Card.vue'
import Dropdown from '@app/components/ui/dropdown/Dropdown.vue'
import Label from '@app/components/ui/dropdown/Label.vue'
import FieldError from '@app/components/ui/form/FieldError.vue'
import { useLocale } from '@app/composables/useLocale'
import { useModelSelect } from '@app/composables/useModelSelect'
import { type Branch, type LocationType, type WorkType } from '@app/types/shared'
import { useForm } from '@inertiajs/vue3'
import VueDatePicker from '@vuepic/vue-datepicker'
import { ref, toRefs, watch, watchEffect, type PropType } from 'vue'
import { route } from 'ziggy-js'

const props = defineProps({
    branches: {
        type: Array as PropType<Branch[]>,
        required: true,
    },
    locationTypes: {
        type: Array as PropType<LocationType[]>,
        required: true,
    },
    workTypes: {
        type: Array as PropType<WorkType[]>,
        required: true,
    },
    dismissable: Boolean,
})

const emit = defineEmits(['close'])
const { getLocale } = useLocale()

const params = route().params

// temp
const from = ref([
    { id: 1, name: '10:00', hour: 12 },
    { id: 2, name: '12:00', hour: 14 },
    { id: 3, name: '14:00', hour: 16 },
])
// temp
const to = ref([
    { id: 1, name: '16:00', hour: 18 },
    { id: 2, name: '18:00', hour: 20 },
    { id: 3, name: '20:00', hour: 22 },
])

const { branches, locationTypes, workTypes } = toRefs(props)
const selectedWorkType = useModelSelect(workTypes.value, params.workType, { by: 'slug' })
const selectedClientProject = useModelSelect(selectedWorkType.value?.client_projects || [], selectedWorkType.value?.client_projects[0].id)
const selectedLocationType = useModelSelect(locationTypes.value, locationTypes.value[0].id)
const selectedBranch = useModelSelect(branches.value, branches.value[0]?.id)
const selectedDate = ref(new Date())
const selectedFrom = useModelSelect(from.value, from.value[0].id)
const selectedTo = useModelSelect(to.value, to.value[0].id)

const form = useForm({
    work_type_id: selectedWorkType.value?.id || null,
    client_project_id: selectedClientProject.value?.id || null,
    location_type_id: selectedLocationType.value?.id || null,
    branch_id: selectedBranch.value?.id || null,
    from: '',
    to: '',
})

watch(selectedWorkType, () => {
    selectedClientProject.value = selectedWorkType.value?.client_projects[0]
})

watchEffect(() => {
    form.work_type_id = selectedWorkType.value?.id || null
    form.client_project_id = selectedClientProject.value?.id || null
    form.branch_id = selectedBranch.value?.id || null

    form.from = new Date(new Date(selectedDate.value).setHours(selectedFrom.value?.hour, 0, 0, 0)).toISOString()
    form.to = new Date(new Date(selectedDate.value).setHours(selectedTo.value?.hour, 0, 0, 0)).toISOString()
})

const submit = () => {
    form.post(route('shifts.availability.store'), {
        preserveScroll: true,
        onSuccess: () => emit('close'),
    })
}
</script>

<template>
    <Card dismissable @close="emit('close')" id="detail-card" class="w-full md:w-1/3">
        <div class="flex w-full flex-col items-start gap-4">
            <div class="flex gap-2">
                <div class="w-full text-center text-lg font-medium leading-6 text-gray-900">{{ $t('shifts.create_availability.title') }}</div>
            </div>
            <div class="flex w-full flex-col gap-4">
                <Dropdown
                    showLabel
                    :label="$t('attributes.work_type')"
                    :items="workTypes"
                    v-model="selectedWorkType"
                    v-model:form="form.work_type_id"
                    propertyName="name"
                    by="slug"
                />
                <FieldError :message="form.errors.work_type_id" />
                <Dropdown
                    showLabel
                    :label="$t('attributes.client_project')"
                    :items="selectedWorkType?.client_projects"
                    v-model="selectedClientProject"
                    v-model:form="form.client_project_id"
                    propertyName="name"
                />
                <FieldError :message="form.errors.client_project_id" />
                <Dropdown
                    showLabel
                    :label="$t('attributes.location_type')"
                    :items="locationTypes"
                    v-model="selectedLocationType"
                    v-model:form="form.location_type_id"
                    propertyName="name"
                />
                <FieldError :message="form.errors.location_type_id" />
                <Dropdown
                    showLabel
                    :label="$t('attributes.branch')"
                    :items="branches"
                    v-model="selectedBranch"
                    v-model:form="form.branch_id"
                    propertyName="name"
                />
                <FieldError :message="form.errors.location_type_id" />
                <div class="flex flex-col gap-1">
                    <Label> {{ $t('attributes.date') }}</Label>
                    <VueDatePicker
                        id="availability_date"
                        v-model="selectedDate"
                        :locale="getLocale().code"
                        :auto-position="false"
                        auto-apply
                        :enable-time-picker="false"
                        text-input
                        format="dd-MM-yyyy"
                        placeholder="Select a date"
                    />
                </div>
                <div class="flex gap-2">
                    <Dropdown id="from" v-model="selectedFrom" showLabel label="Start time" class="w-full" :items="from" propertyName="name" />
                    <Dropdown id="to" v-model="selectedTo" showLabel label="End time" class="w-full" :items="to" propertyName="name" />
                </div>
            </div>
        </div>
        <div class="flex w-full flex-row-reverse justify-between">
            <SaveButton @click="submit">Create</SaveButton>
        </div>
    </Card>
</template>
