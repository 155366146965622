import type { Sluggable } from '@app/types/shared'
import { ref, toValue, watch, type MaybeRef } from 'vue'

interface Model {
    id: number
}

function isSluggable(model: Model | Sluggable): model is Sluggable {
    return typeof (model as Sluggable).slug !== 'undefined'
}

function isModel(model: Model | Sluggable): model is Model {
    return typeof (model as Model).id !== 'undefined'
}

type SelectProps = {
    by: 'id' | 'slug'
    autoselectOnChange: boolean
}

export function useModelSelect<T extends Model | Sluggable>(models: MaybeRef<T[]>, initialValue?: number | string | null, options: Partial<SelectProps> = {}) {
    const selected = ref<T>()

    const { by, autoselectOnChange } = {
        by: 'id',
        autoselectOnChange: false,
        ...options,
    }

    function findByIdOrSlug(model: T) {
        if (by === 'slug' && isSluggable(model)) {
            return model.slug === initialValue
        }

        if (by === 'id' && isModel(model)) {
            return model.id === initialValue
        }

        return false
    }

    if (initialValue) {
        const normalizedModels = toValue(models)

        selected.value = normalizedModels.find(findByIdOrSlug)
    }

    watch(models, () => {
        const normalizedModels = toValue(models)

        if (!selected.value || !normalizedModels.some((model) => model[by] === selected.value[by])) {
            if (autoselectOnChange) {
                selected.value = normalizedModels[0]
            } else {
                selected.value = undefined
            }
        }
    })

    return selected
}
